import React from 'react';
import HowItWorks from "./HowItWorks";
import TopProjects from "./TopProjects";
import Pricing from "./Pricing";
import gemGunner from "../assets/gem.svg";
import {Helmet} from "react-helmet-async";
import screenshot from "../assets/screenshot.png";
import Footer from "./FooterSocials";
import Header from "./Header";
import {NavLink} from "react-router-dom";


const Intro = ({id}) => {
    return (
        <main className="container mx-auto px-4 py-12 text-center" id={id}>
            <img src={gemGunner} alt="Gem Gunner" className="h-40" style={{margin: "-5rem auto 1rem"}}/>
            <div
                className="mb-4 inline-flex items-center bg-purple-800 text-purple-300 px-3 py-1 rounded-full text-sm">
                <span className="mr-2 text-xs font-bold bg-purple-600 text-white px-2 py-0.5 rounded">NEW</span>
                Preview now available!
            </div>
            <h1 className="text-6xl font-bold mb-4">
                Find Innovative Projects <br/>
                <span
                    className="bg-gradient-to-r from-purple-400 to-pink-400 text-transparent bg-clip-text">using AI</span>
            </h1>
            <p className="text-xl mb-8">
                Use AI to rank creativity of contracts deployed on <br/>
                ethereum, polygon and solana.
            </p>
            <NavLink to="/dashboard">
                <button className="bg-white text-purple-900 font-bold py-3 px-8 rounded-lg text-lg">
                    Try it Now!
                </button>
            </NavLink>
        </main>
    )
}

const Home = () => {

    const description = "Find innovative projects by using AI to rank creativity of contracts deployed on ethereum, polygon and solana."
    const type = "website"
    const title = "Gem Gunner"
    const name = "GemGunner"

    return (
        <>
            <Helmet>
                { /* Standard metadata tags */}
                <title>Gem Gunner</title>
                <meta name='description' content={description}/>
                { /* End standard metadata tags */}
                { /* Facebook tags */}
                <meta property="og:type" content={type}/>
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={description}/>
                <meta property="og:image" content={screenshot}/>
                { /* End Facebook tags */}
                { /* Twitter tags */}
                <meta name="twitter:creator" content={name}/>
                <meta name="twitter:card" content={type}/>
                <meta name="twitter:title" content={title}/>
                <meta name="twitter:description" content={description}/>
                { /* End Twitter tags */}
            </Helmet>
            <div className="text-white min-h-screen">
                <Header/>
                <Intro id="features"/>
                <HowItWorks id="how-it-works"/>
                <TopProjects id="top-projects"/>
                <Pricing id="pricing"/>
                <Footer id="token"/>
            </div>
        </>
    );
};

export default Home;