import React from 'react';
import Home from "./components/Home";
import {HelmetProvider} from 'react-helmet-async';
import {Navigate, Routes, Route, BrowserRouter} from "react-router-dom";
import MainListing from "./components/admin/MainListing";
import UserDashboard from "./components/UserDashboard";

// Mock authentication function
const isAdmin = () => {
    // In a real application, this would check if the user is authenticated as an admin
    // return localStorage.getItem('isAdmin') === 'true';
    return true;
};

const ProtectedRoute = ({children}) => {
    if (!isAdmin()) {
        return <Navigate to="/" replace/>;
    }
    return children;
};


const App = () => {
    return (
        <div className="bg-black min-h-dvh">
        <HelmetProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/dashboard" element={<UserDashboard />}/>
                    <Route
                        path="/admin"
                        element={
                            <ProtectedRoute>
                                <MainListing/>
                            </ProtectedRoute>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </HelmetProvider>
        </div>
    )
}


export default App;