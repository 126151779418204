import React, { useState } from 'react';
import { Search, Star, Clock, TrendingUp, Filter, ChevronLeft, ChevronRight } from 'lucide-react';
import Header from "./Header";

// Mock data for projects
const mockProjects = [
    {
        id: 1,
        name: "DecentraVault",
        chain: "Ethereum",
        innovationScore: 94,
        description: "Next-generation decentralized vault protocol with AI-powered yield optimization and risk management.",
        logo: "/api/placeholder/48/48",
        developerName: "DeFi Labs",
        socials: {
            twitter: { link: "#", username: "@decentravault" },
            telegram: { link: "#", username: "decentravault" },
            discord: { link: "#", username: "decentravault" }
        },
        deployDate: "2024-11-28",
        isPremium: false
    },
    {
        id: 2,
        name: "MetaWorld NFT",
        chain: "Polygon",
        innovationScore: 88,
        description: "Revolutionary NFT marketplace with dynamic pricing and cross-chain interoperability.",
        logo: "/api/placeholder/48/48",
        developerName: "Meta Systems",
        socials: {
            twitter: { link: "#", username: "@metaworld" },
            telegram: { link: "#", username: "metaworld" }
        },
        deployDate: "2024-11-29",
        isPremium: true
    },
    {
        id: 3,
        name: "AstroSwap",
        chain: "Solana",
        innovationScore: 91,
        description: "High-performance DEX with innovative AMM model and minimal price impact.",
        logo: "/api/placeholder/48/48",
        developerName: "Astro Finance",
        socials: {
            twitter: { link: "#", username: "@astroswap" },
            telegram: { link: "#", username: "astroswap" }
        },
        deployDate: "2024-11-30",
        isPremium: false
    }
];

// Project Card Component
const ProjectCard = ({ project }) => {
    return (
        <div className="bg-gray-800 rounded-lg p-4 hover:bg-gray-700 transition-colors">
            <div className="flex items-start space-x-3">
                <img src={project.logo} alt={project.name} className="w-10 h-10 rounded-full" />
                <div className="flex-1">
                    <div className="flex justify-between items-start">
                        <div>
                            <h3 className="font-semibold text-lg">{project.name}</h3>
                            <p className="text-sm text-gray-400">{project.chain}</p>
                        </div>
                        {project.isPremium && (
                            <span className="bg-purple-600 text-xs px-2 py-1 rounded-full">Premium</span>
                        )}
                    </div>
                    <p className="text-sm text-gray-300 mt-2">{project.description}</p>

                    <div className="mt-4">
                        <div className="flex justify-between items-center mb-2">
                            <span className="text-sm text-gray-400">Innovation Score</span>
                            <span className="text-sm font-semibold">{project.innovationScore}</span>
                        </div>
                        <div className="h-2 bg-gray-700 rounded-full">
                            <div
                                className="h-full bg-gradient-to-r from-purple-500 to-pink-500 rounded-full"
                                style={{ width: `${project.innovationScore}%` }}
                            />
                        </div>
                    </div>

                    <div className="mt-4 flex justify-between items-center">
                        <div className="flex space-x-3">
                            {Object.entries(project.socials).map(([platform, data]) => (
                                <a
                                    key={platform}
                                    href={data.link}
                                    className="text-gray-400 hover:text-purple-400 transition-colors"
                                >
                                    {platform}
                                </a>
                            ))}
                        </div>
                        <span className="text-sm text-gray-400">
              Deployed: {new Date(project.deployDate).toLocaleDateString()}
            </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Main Dashboard Component
const DateScroller = () => {
    const [selectedDate, setSelectedDate] = useState('2024-11-30');

    const dates = [
        { date: '2024-11-28', day: 'Thu', projects: 0, text: 'No Projects' },
        { date: '2024-11-29', day: 'Fri', projects: 0, text: 'No Projects' },
        { date: '2024-11-30', day: 'Sat', projects: 15, text: '15 Projects' },
        { date: '2024-12-01', day: 'Sun', projects: 9, text: '9 Projects' },
        { date: '2024-12-02', day: 'Mon', projects: 11, text: '11 Projects' },
        { date: '2024-12-03', day: 'Tue', projects: 10, text: '10 Projects' },
        { date: '2024-12-04', day: 'Wed', projects: 12, text: '12 Projects' },
    ];

    return (
        <div className="relative flex items-center bg-white bg-opacity-5 rounded-lg p-2">
            <button className="absolute left-2 z-10 p-2 rounded-full bg-gray-800 hover:bg-gray-700">
                <ChevronLeft size={20} />
            </button>

            <div className="flex-1 overflow-hidden mx-8">
                <div className="flex justify-between">
                    {dates.map((dateObj) => {
                        const isSelected = dateObj.date === selectedDate;
                        const formattedDate = new Date(dateObj.date).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric'
                        });

                        return (
                            <button
                                key={dateObj.date}
                                onClick={() => setSelectedDate(dateObj.date)}
                                className={`flex-1 px-2 py-3 rounded-lg transition-colors ${
                                    isSelected
                                        ? 'bg-purple-600'
                                        : 'hover:bg-gray-800'
                                }`}
                            >
                                <div className="text-sm text-gray-400">{dateObj.day}</div>
                                <div className="font-bold">{formattedDate}</div>
                                <div className={`text-sm ${dateObj.projects > 0 ? 'text-purple-400' : 'text-gray-500'}`}>
                                    {dateObj.text}
                                </div>
                            </button>
                        );
                    })}
                </div>
            </div>

            <button className="absolute right-2 z-10 p-2 rounded-full bg-gray-800 hover:bg-gray-700">
                <ChevronRight size={20} />
            </button>
        </div>
    );
};

const UserDashboard = () => {
    const [activeFilter, setActiveFilter] = useState('trending');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedChain, setSelectedChain] = useState('all');

    const filterButtons = [
        { id: 'trending', label: 'Trending', icon: TrendingUp },
        { id: 'newest', label: 'Newest', icon: Clock },
        { id: 'favorites', label: 'Favorites', icon: Star },
    ];

    const chains = ['all', 'Ethereum', 'Polygon', 'Solana'];

    return (
        <div className="min-h-screen bg-gray-900 text-white">
            <Header />

            {/* Main Content */}
            <main className="container mx-auto py-6 px-4">
                {/* Date Scroller */}
                <div className="mb-8">
                    <DateScroller />
                </div>
                {/* Filters and Search */}
                <div className="mb-8">
                    <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">
                        {/* Filter Buttons */}
                        <div className="flex space-x-2">
                            {filterButtons.map(({ id, label, icon: Icon }) => (
                                <button
                                    key={id}
                                    onClick={() => setActiveFilter(id)}
                                    className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
                                        activeFilter === id
                                            ? 'bg-purple-600 text-white'
                                            : 'bg-gray-800 hover:bg-gray-700'
                                    }`}
                                >
                                    <Icon size={18} />
                                    <span>{label}</span>
                                </button>
                            ))}
                        </div>

                        {/* Search and Chain Filter */}
                        <div className="flex space-x-2 w-full md:w-auto">
                            <div className="relative flex-1 md:flex-none">
                                <input
                                    type="text"
                                    placeholder="Search projects..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="w-full md:w-64 bg-gray-800 rounded-lg pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-600"
                                />
                                <Search className="absolute left-3 top-2.5 text-gray-400" size={18} />
                            </div>

                            <select
                                value={selectedChain}
                                onChange={(e) => setSelectedChain(e.target.value)}
                                className="bg-gray-800 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-600"
                            >
                                {chains.map(chain => (
                                    <option key={chain} value={chain}>
                                        {chain.charAt(0).toUpperCase() + chain.slice(1)}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                {/* Projects Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {mockProjects.map(project => (
                        <ProjectCard key={project.id} project={project} />
                    ))}
                </div>

                {/* Pagination */}
                <div className="mt-8 flex justify-center items-center space-x-4">
                    <button className="p-2 rounded-lg bg-gray-800 hover:bg-gray-700 transition-colors">
                        <ChevronLeft size={20} />
                    </button>
                    <span className="text-gray-400">Page 1 of 10</span>
                    <button className="p-2 rounded-lg bg-gray-800 hover:bg-gray-700 transition-colors">
                        <ChevronRight size={20} />
                    </button>
                </div>
            </main>
        </div>
    );
};

export default UserDashboard;