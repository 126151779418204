import React, { useState, useEffect } from 'react';
import { Twitter, MessageCircle, BarChart2, Lock } from 'lucide-react';
import axiosApi from "../utils/api";

const TopProjectsSection = ({id}) => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulating API call
        setLoading(true);
        fetchProjects();
    }, []);

    const fetchProjects = () => {
        axiosApi.get("/projects?valid=true").then((response) => {
            setProjects(response.data.projects);
            setLoading(false);
        }).catch((error) => {
            console.error("Error fetching projects:", error);
        });
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const ProjectCard = ({ project }) => (
        <div className="bg-gray-800 rounded-lg p-4 shadow-lg flex flex-col h-full" id={id}>
            <div className="flex items-start mb-2">
                {project.isPremium ? (
                    <div className="w-8 h-8 mr-2 rounded-full bg-gray-700 flex items-center justify-center flex-shrink-0">
                        <Lock size={16} className="text-purple-400" />
                    </div>
                ) : (
                    project.logo && <img src={project.logo} alt={`${project.projectName} logo`} className="w-8 h-8 mr-2 rounded-full flex-shrink-0" />
                )}
                <div>
                    <h3 className="text-sm font-semibold">
                        {project.isPremium ? "Premium Project" : project.projectName}
                    </h3>
                    <p className="text-xs text-gray-400">
                        {project.isPremium ? "Unlock to view details" : project.developerName}
                    </p>
                </div>
            </div>
            <p className="text-xs text-gray-300 mb-2 flex-grow line-clamp-2">{project.description}</p>
            <div className="mt-auto">
                <div className="mb-2">
                    <div className="bg-purple-900 rounded-full h-1.5">
                        <div
                            className="bg-purple-500 h-1.5 rounded-full"
                            style={{width: `${Math.round(project.percentScore)}%`}}
                        ></div>
                    </div>
                    <p className="text-xs text-gray-400 mt-1">Score: {project.percentScore.toFixed(2)}%</p>
                </div>
                <p className="text-xs text-gray-400 mb-2">Deployed: {formatDate(project.firstDeploy)}</p>
                {!project.isPremium ? (
                    <div className="flex justify-between">
                        {project.socials.twitter && (
                            <a href={project.socials.twitter.link} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                                <Twitter size={16} />
                            </a>
                        )}
                        {project.socials.telegram && (
                            <a href={project.socials.telegram.link} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                                <MessageCircle size={16} />
                            </a>
                        )}
                        <a href={project.website} target="_blank" rel="noopener noreferrer" className="text-green-400 hover:text-green-300">
                            <BarChart2 size={16} />
                        </a>
                    </div>
                ) : (
                    <button className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center">
                        <Lock size={16} className="mr-2" />
                        Unlock Premium
                    </button>
                )}
            </div>
        </div>
    );

    return (
        <section className="container mx-auto px-4 py-16">
            <h2 className="text-3xl font-bold mb-8 text-center">Top Projects This Week</h2>
            {loading ? (
                <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    {projects.map((project) => (
                        <ProjectCard key={project.id} project={project} />
                    ))}
                </div>
            )}
        </section>
    );
};

export default TopProjectsSection;