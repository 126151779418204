import React from 'react';

const FilterBar = ({filters, handleFilterChange, dateFilterOptions}) => (
    <div className="mb-4 flex flex-wrap gap-4">
        <div className="p-2 rounded">
            <span className="pr-2 font-bold">Sort:</span>
            <select name="sortBy" value={filters.sortBy} onChange={handleFilterChange}
                    className="bg-black rounded p-2 mr-2">
                <option value="totalScore">Total Score</option>
                <option value="projectScore">Project Score</option>
                <option value="firstDeploy">First Deploy</option>
                <option value="projectName">Project Name</option>
            </select>
            <select name="order" value={filters.order} onChange={handleFilterChange}
                    className="bg-black rounded p-2">
                <option value="desc">Descending</option>
                <option value="asc">Ascending</option>
            </select>
        </div>
        <div className="p-2 rounded">
            <span className="pr-2 font-bold">Filter:</span>
            <select name="valid" value={filters.valid} onChange={handleFilterChange}
                    className="bg-black rounded p-2 mr-2">
                <option value="">All Projects</option>
                <option value="true">Valid Only</option>
                <option value="false">Invalid Only</option>
            </select>
            <select name="size" value={filters.size} onChange={handleFilterChange}
                    className="bg-black rounded p-2 mr-2">
                <option value="10">10 per page</option>
                <option value="20">20 per page</option>
                <option value="50">50 per page</option>
            </select>
            {/*<select name="first_deploy__gte" value={filters.first_deploy__gte} onChange={handleFilterChange}*/}
            {/*        className="bg-black rounded p-2 mr-2">*/}
            {/*    <option value="">All Time</option>*/}
            {/*    <option value={dateFilterOptions.day}>Past Day</option>*/}
            {/*    <option value={dateFilterOptions.week}>Past Week</option>*/}
            {/*    <option value={dateFilterOptions.month}>Past Month</option>*/}
            {/*    <option value={dateFilterOptions.year}>Past Year</option>*/}
            {/*</select>*/}
        </div>
    </div>
);

export default FilterBar;