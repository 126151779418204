import React, {useState, useRef, useEffect} from 'react';
import {BadgeCheck, ChevronDown, ChevronUp, FilePlus2, SquareSlash} from 'lucide-react';
import _ from 'lodash';
import axiosApi from "../../utils/api";

const VerticalDateSlider = ({filters, setFilters}) => {
    const today = new Date(filters.first_deploy__gte);
    const [selectedDate, setSelectedDate] = useState(today);
    const [showMonthPicker, setShowMonthPicker] = useState(false);
    const [dateRange, setDateRange] = useState({
        start: -365 * 2,  // Days before today
        end: 1        // Today (no future dates)
    });

    const sliderRef = useRef(null);
    const [dailyStats, setDailyStats] = useState({});

    // Generate dates based on the current range
    const dates = React.useMemo(() => {
        return Array.from(
            {length: dateRange.end - dateRange.start},
            (_, i) => {
                const date = new Date(today);
                date.setDate(date.getDate() + (dateRange.start + i));
                return date;
            }
        );
    }, [dateRange]);


    // Fetch daily stats for each date
    useEffect(() => {
        axiosApi.get('/stats/daily', {
            params: {
                start_date: dates[0].toISOString().split('T')[0],
            }
        }).then((response) => {
            setDailyStats(response.data);
        })
    }, [dates])

    // Generate unique months for the month picker
    const months = React.useMemo(() =>
            Array.from(new Set(dates.map(date =>
                `${date.toLocaleString('default', {month: 'long'})} ${date.getFullYear()}`
            ))),
        [dates]
    );

    // // Handle infinite scroll
    // const handleScroll = _.throttle(() => {
    //     if (loadingRef.current || !sliderRef.current) return;
    //
    //     const {scrollTop, scrollHeight, clientHeight} = sliderRef.current;
    //     const scrollPosition = scrollTop / scrollHeight;
    //
    //     // Only load more dates when scrolling near the top (20% threshold)
    //     if (scrollPosition < 0.2) {
    //         loadingRef.current = true;
    //
    //         setDateRange(prevRange => ({
    //             ...prevRange,
    //             start: prevRange.start - 180 // Load 6 more months worth of dates
    //         }));
    //
    //         // Maintain scroll position when loading more dates at the top
    //         requestAnimationFrame(() => {
    //             if (sliderRef.current) {
    //                 sliderRef.current.scrollTop = scrollHeight * 0.4;
    //             }
    //         });
    //
    //         setTimeout(() => {
    //             loadingRef.current = false;
    //         }, 500);
    //     }
    // }, 150);

    // useEffect(() => {
    //     const slider = sliderRef.current;
    //     if (slider) {
    //         slider.addEventListener('scroll', handleScroll);
    //     }
    //
    //     return () => {
    //         if (slider) {
    //             slider.removeEventListener('scroll', handleScroll);
    //         }
    //     };
    // }, []);

    const handleDateClick = (date) => {
        setSelectedDate(date);
        const startOfDay = new Date(date);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(date);
        endOfDay.setHours(23, 59, 59, 999);
        setFilters(prev => ({
            ...prev,
            first_deploy__gte: startOfDay.toISOString(),
            first_deploy__lte: endOfDay.toISOString()
        }));
    };

    const scrollToDate = (date) => {
        const targetElement = document.getElementById(`date-${date.toISOString()}`);
        if (targetElement && sliderRef.current) {
            targetElement.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    };

    const scrollToMonth = (monthYear) => {
        const [month, year] = monthYear.split(' ');
        const targetDate = dates.find(date =>
            date.toLocaleString('default', {month: 'long'}) === month &&
            date.getFullYear().toString() === year
        );

        if (targetDate) {
            scrollToDate(targetDate);
        }
        setShowMonthPicker(false);
    };


    // group dates by month
    const groupedDates = _.groupBy(dates, date => `${date.getMonth()}-${date.getFullYear()}`);

    return (
        <div className="flex flex-col h-full mr-4 rounded-lg w-64">
            {/* Selected Date Display */}
            <div className="p-2 border-b border-gray-700">
                <button
                    onClick={() => scrollToDate(selectedDate)}
                    className="w-full px-4 py-2 text-purple-400 rounded-md text-center transition-colors"
                >
                    {selectedDate.toLocaleDateString('default', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })}
                </button>
            </div>

            {/* Month Quick Jump */}
            <div className="relative p-2 border-b border-gray-700">
                <button
                    onClick={() => setShowMonthPicker(!showMonthPicker)}
                    className="w-full px-4 py-2 text-left rounded-md flex justify-between items-center"
                >
                    <span>Jump to Month</span>
                    {showMonthPicker ? <ChevronUp size={20}/> : <ChevronDown size={20}/>}
                </button>

                {showMonthPicker && (
                    <div
                        className="absolute top-full left-0 right-0 z-50 mt-1 border border-purple-500 bg-black rounded-md shadow-lg custom-scrollbar max-h-48 overflow-y-auto">
                        {months.map((month) => (
                            <button
                                key={month}
                                onClick={() => scrollToMonth(month)}
                                className="w-full px-4 py-2 text-left hover:bg-gray-600 text-sm"
                            >
                                {month}
                            </button>
                        ))}
                    </div>
                )}
            </div>

            {/* Date Slider */}
            <div
                ref={sliderRef}
                className="flex-1 overflow-y-auto custom-scrollbar scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800"
            >
                {Object.entries(groupedDates).reverse().map(([month, monthDates]) => (
                    <div key={month}>
                        <div
                            className="sticky top-0 bg-gray-800 z-0 px-4 py-2 font-semibold text-purple-400 text-sm">
                            {monthDates[0].toLocaleString('default', {month: 'long', year: 'numeric'})}
                            <div className="text-xs text-center text-gray-400 grid grid-cols-4">
                                <div className="text-left">Date</div>
                                <div>New</div>
                                <div>Ignored</div>
                                <div>Valid</div>
                            </div>
                        </div>
                        {monthDates.reverse().map((date) => {
                            const isSelected = date.toDateString() === selectedDate.toDateString();
                            return (
                                <div key={date.toISOString()} id={`date-${date.toISOString()}`}>
                                    <button
                                        onClick={() => handleDateClick(date)}
                                        className={`w-full px-4 text-center py-2 grid grid-cols-4 gap-2 text-sm transition-colors ${
                                            isSelected
                                                ? 'bg-purple-700 text-white'
                                                : 'hover:bg-gray-700'
                                        }`}
                                    >
                                        <div className="text-left">
                                            <div className="font-bold">
                                                {date.toLocaleDateString('default', {
                                                    day: '2-digit'
                                                })}
                                            </div>
                                            <div>
                                                {date.toLocaleDateString('default', {
                                                    month: 'short'
                                                })}
                                            </div>
                                        </div>
                                        <div title="Projects Discovered">
                                        <span className="ml-2 text-gray-400 text-center inline-block">
                                            {dailyStats[date.toISOString().split('T')[0]]?.created || 0}<br/>
                                            <FilePlus2 size={16} className="inline"/>
                                        </span>
                                        </div>
                                        <div title="Projects Ignored">
                                        <span className="ml-2 text-gray-400 text-center inline-block">
                                            {dailyStats[date.toISOString().split('T')[0]]?.ignored || 0}<br/>
                                            <SquareSlash size={16} className="inline"/>
                                        </span>
                                        </div>
                                        <div title="Projects Validated">
                                        <span className="ml-2 text-gray-400 text-center inline-block">
                                            {dailyStats[date.toISOString().split('T')[0]]?.validated || 0}<br/>
                                            <BadgeCheck size={16} className="inline"/>
                                        </span>
                                        </div>
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VerticalDateSlider;