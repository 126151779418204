import React from 'react';
import {ChevronLeftIcon, ChevronRight} from "lucide-react";

const Pagination = ({ filters, totalPages, handlePageChange }) => (
    <div className="mt-4 flex justify-center">
        <button onClick={() => handlePageChange(filters.page - 1)} disabled={filters.page === 1} className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-1 px-2 rounded mr-2 disabled:opacity-50">
            <ChevronLeftIcon size={16}/>
        </button>
        <span className="py-2 px-4">Page {filters.page} of {totalPages}</span>
        <button onClick={() => handlePageChange(filters.page + 1)} disabled={filters.page === totalPages} className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-1 px-2 rounded ml-2 disabled:opacity-50">
            <ChevronRight size={16}/>
        </button>
    </div>
);

export default Pagination;